import React, { useEffect, useRef,useContext } from 'react';
import './styles/main.scss';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context';

export default function Onboarding(props) {
    const [persona, setPersona] = React.useState({});
    const [step, setStep] = React.useState(1);
    const [didPlay, setDidPlay] = React.useState(false);
    const { state, dispatch } = useContext(GlobalContext);
    const videoRef = useRef(null);
    const navigate = useNavigate();

    const answerStep1= (answer) => {
        setPersona({description: answer});
        setStep(2);
        videoRef.current.currentTime = 4.20;
    }

    const answerStep2= (answer) => {
        let newPersona={...persona, interests:[answer]};
        setPersona(newPersona);        
        setStep(3);
        videoRef.current.currentTime = 6.5;
    }

    const answerStep3= (answer) => {
        let newPersona={...persona, location:answer};
        setPersona(newPersona);
        navigate('/home'); 

        dispatch({ type: 'SET', payload: { persona:newPersona } });
    }


    const play=()=>{
        if (!didPlay) {
            videoRef.current.play();
            videoRef.current.currentTime = 0;
            setDidPlay(true);
        }
    }

    useEffect(() => {
       play();
      }, []);

    const handleTimeUpdate = () => {
        // Loop
        if (videoRef.current.currentTime<25 && videoRef.current.currentTime>24) {
            videoRef.current.currentTime = 12;
        }


        if (step===1 && videoRef.current.currentTime>4 && videoRef.current.currentTime<5) {
            videoRef.current.currentTime = 12;
        };

        if (step===3 && videoRef.current.currentTime>8 && videoRef.current.currentTime<9) {
            videoRef.current.currentTime = 12;
        };

         if (step===2 && videoRef.current.currentTime>6.20 && videoRef.current.currentTime<7) {
            videoRef.current.currentTime = 12;
        };
      };

      const skip=()=>{
        let newPersona={description:'Gebruiker', interests:['Vergeetachtig','Eenzaamheid'], location:'Nederland'};
        setPersona(newPersona);
        navigate('/home'); 
        dispatch({ type: 'SET', payload: { persona:newPersona } });
      }


    return (
        <div className="onboarding">

        <div className="onboarding__skip" onClick={skip}>
          <div className="onboarding__skip__link">Sla over</div>
        </div>

            <video ref={videoRef} autoPlay className="background__video" onTimeUpdate={handleTimeUpdate}>
                <source src="video.mov" type="video/mp4; codecs=hevc" />
               <source src="/video.webm" type="video/webm" />
            </video>

            {step===1 && (
            <div className="step step1">
                <div className="questions__container">
                    <div className="questions__question">
                        Goede dag! Ik ben Evi en help u met uw vragen.
                    </div>
                    <div className="questions__question">
                    Waar kan ik u mee helpen?
                    </div>
                </div>
                <div className="questions__options">
                    <div className="questions__option" onClick={() => answerStep1('Zorgzoekend')}>Ik zoek informatie voor mezelf</div>
                    <div className="questions__option" onClick={() => answerStep1('Mantelzorger')}>Ik zorg voor een ander en heb een vraag</div>                
                    <div className="questions__option" onClick={() => answerStep1('Zorgprofessional')}>Ik ben een professional</div>                
                </div>
            </div>
            )}
               {step===3 && (
            <div className="step step1">
                <div className="questions__container">
                    <div className="questions__question">
                        Om welke regio gaat het?
                    </div>
                </div>
                <div className="questions__options">
                    <div className="questions__option" onClick={() => answerStep3('Oosterhout')}>Oosterhout</div>
                    <div className="questions__option" onClick={() => answerStep3('Etten-Leur')}>Etten-Leur</div>                
                </div>
            </div>
            )}
               {step===2 && (
            <div className="step step3">
                <div className="questions__container">
                    <div className="questions__question">
                        Over welk onderwerp heeft u een vraag?
                    </div>
                </div>
                <div className="questions__options">
                    <div className="questions__option" onClick={() => answerStep2('Vergeetachtig')}>Vergeetachtig</div>
                    <div className="questions__option" onClick={() => answerStep2('Eenzaamheid')}>Eenzaamheid</div>                
                </div>
            </div>
            )}
            
        </div>
    );
  }