import React, { createContext, useReducer } from 'react';

const GlobalContext = createContext();

const initialState = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : {};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET':
        let newState = {
            ...state,
            ...action.payload
        };
        localStorage.setItem('state', JSON.stringify(newState));
      return newState;    

    case 'RESET':
      localStorage.removeItem('state');
      return {};    
    // Add other cases here
    default:
      return state;
  }
};

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };